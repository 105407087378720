import "tailwindcss/tailwind.css";
import "../styles/main.scss";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import analytics from "../lib/analytics";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";

import { store, persistor } from "../store";
import { GA4_EXPERIMENT_VIEW } from "../components/TrackingScripts/helpers";
import { measurementId } from "../components/TrackingScripts/LogGA";
import { NextIntlClientProvider } from "next-intl";
import Script from "next/script";

const NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY =
  process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY;
const NEXT_PUBLIC_RECAPTCHA_SITE_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: true,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    analytics.track(GA4_EXPERIMENT_VIEW, {
      experiment_id: experiment.key,
      variation_id: result.key,
    });
  },
});

function MyApp({ Component, pageProps }) {
  const { events, isReady, query } = useRouter();
  const { dir, recaptchaSiteKey = NEXT_PUBLIC_RECAPTCHA_SITE_KEY } = pageProps;
  const [gaClientId, setGaClientId] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isReady && !gaClientId) {
      gtag("get", measurementId, "client_id", (cId) => {
        setGaClientId(cId);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isReady, gaClientId]);

  useEffect(() => {
    let isMounted = true;
    const { akid } = query;
    if (isMounted && isReady && !userId && akid) {
      const akidSplit = akid.split(".") || [];
      const userId = akidSplit.length === 2 ? akidSplit[1] : null;
      setUserId(userId);
    }
    return () => {
      isMounted = false;
    };
  }, [isReady, userId, query]);

  useEffect(() => {
    // Load features async
    let isMounted = true;
    if (isMounted && isReady && (userId || gaClientId)) {
      growthbook.setAttributes({
        id: userId || gaClientId,
        userId,
        clientId: gaClientId,
        url: window.location.href,
      });
    }

    if (isMounted && isReady) {
      growthbook.loadFeatures({ autoRefresh: true });
    }

    return () => {
      isMounted = false;
    };
  }, [isReady, userId, gaClientId]);

  useEffect(() => {
    document.body.dir = dir;
  }, [dir]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && isReady) {
      analytics.page();
    }

    const handleRouteChange = () => {
      analytics.page();
    };

    events.on("routeChangeComplete", handleRouteChange);
    return () => {
      events.off("routeChangeComplete", handleRouteChange);
      isMounted = false;
    };
  }, [events, isReady]);

  return (
    <>
      <Script
        src="https://polyfill.io/v3/polyfill.min.js?version=3.111.0&features=globalThis%2CIntersectionObserverEntry%2CIntersectionObserver%2CMutationObserver%2CResizeObserver%2Cperformance.now%2CArrayBuffer%2CIntl.Locale"
        strategy="beforeInteractive"
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GrowthBookProvider growthbook={growthbook}>
            <GoogleReCaptchaProvider
              reCaptchaKey={recaptchaSiteKey}
              scriptProps={{
                async: true,
                defer: false,
                appendTo: "head",
                nonce: undefined,
              }}
            >
              <NextIntlClientProvider
                messages={pageProps?.messages}
                locale={pageProps?.language || "en"}
              >
                <Component {...pageProps} />
              </NextIntlClientProvider>
            </GoogleReCaptchaProvider>
          </GrowthBookProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default MyApp;
