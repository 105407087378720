import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import { getItem } from "@analytics/localstorage-utils";
import { isLiveEnv } from "./environment";

const originalGoogleAnalytics = googleAnalytics({
  measurementIds: ["G-PMEW5CDHC1"],
  debug: !isLiveEnv,
});

const customIdentify = ({ payload }) => {
  if (!isLiveEnv) return;
  const { userId, mailingId, pageTitle, source } = payload?.traits || {};
  if (gtag) {
    gtag("set", {
      user_id: userId,
      campaign_id: mailingId,
      campaign_name: pageTitle,
      campaign_source: source || "email",
    });
  }
};

const googleAnalyticsOverrides = {
  identify: customIdentify,
};

const customGoogleAnalytics = Object.assign(
  {},
  originalGoogleAnalytics,
  googleAnalyticsOverrides
);

// TODO Enable when cookie preference is ready to be deployed
const isTrackingCookieAccepted = "true"; // getItem("acceptsCookie") === "true";
const plugins = isTrackingCookieAccepted ? [customGoogleAnalytics] : [];

const analytics = Analytics({
  app: "pronto",
  plugins: plugins,
});

export default analytics;
