import { createSelector } from "reselect";
import { fetchPaymentMethod, memberValidate } from "../api/index";
import {
  logEventToFacebook,
  PRODUCT_CATALOG_ID,
  CONTENT_TYPE,
} from "../components/TrackingScripts/LogPixel";
import { FB_EV_INITIATE_CHECKOUT } from "../components/TrackingScripts/helpers";

import {
  isDirectDebitSupported,
  isPaypalSupported,
  getLocalPaymentMethods,
} from "../lib/paymentTypesUtils";
import { batchActions } from "../lib/redux-utils";
import { getPageId, getPageTitle } from "./PageReducer";
import { getMemberId } from "./MemberReducer";
import {
  clearCookie,
  isCookiePresent,
  PAYMENT_METHODS_KEY,
} from "../store/cookieUtils";

export const initialState = {
  currency: "USD",
  currentPaymentType: null,
  currentStep: 0,
  showDirectDebit: true,
  showIdeal: false,
  directDebitOnly: false,
  showSavedPayments: false,
  donationAmount: null,
  selectedAmountButton: null,
  isCustomAmount: false,
  donationBands: {
    USD: [2, 5, 10, 25, 50],
    GBP: [2, 5, 10, 25, 50],
    EUR: [2, 5, 10, 25, 50],
    CHF: [2, 5, 10, 25, 50],
    CAD: [2, 5, 10, 25, 50],
    AUD: [2, 5, 10, 25, 50],
    NZD: [2, 5, 10, 25, 50],
    MXN: [2, 5, 10, 25, 50],
    ARS: [2, 5, 10, 25, 50],
    BRL: [2, 5, 10, 25, 50],
  },
  form: {},
  formId: "",
  formValues: {},
  formErrors: {},
  formSubmitting: false,
  oneClick: false,
  savedPaymentMethods: [],
  paymentTypes: ["card"],
  merchantAccountId: "sumofus",
  merchantAccounts: {
    USD: "sumofus",
    GBP: "sumofus2_GBP",
    EUR: "sumofus2_EUR",
    CHF: "SumOfUs_CHF",
    CAD: "SumOfUs_CAD",
    AUD: "SumOfUs_AUD",
    NZD: "SumOfUs_NZD",
    MXN: "SumOfUs_MXN",
    ARS: "SumOfUs_ARS",
    BRL: "SumOfUs_BRL",
  },
  preselectAmount: false,
  recurring: false,
  recurringDefault: "one_off",
  storeInVault: false,
  oneClickError: false,
  title: "Donate now",
  supportedLocalCurrency: true,
  isWeeklyDonation: false,
  localPaymentClient: null,
};

export const SUPPORTED_LOCAL_PAYMENT_METHODS = ["ideal", "giropay", "sofort"];

export const donationReducer = (state = initialState, action) => {
  if (!action || action.payload === undefined) return state;
  switch (action.type) {
    case CHANGED_STEP: {
      return { ...state, currentStep: action.payload };
    }
    case SET_CURRENCY: {
      return { ...state, currency: action.payload };
    }
    case SET_MERCHANT_ACCOUNT_ID: {
      return { ...state, merchantAccountId: action.payload };
    }
    case SET_DONATION_AMOUNT: {
      return { ...state, donationAmount: action.payload };
    }
    case SET_DONATION_BANDS: {
      return { ...state, donationBands: action.payload };
    }
    case SET_IS_CUSTOM_AMOUNT: {
      return { ...state, isCustomAmount: action.payload };
    }
    case SET_CURRENT_PAYMENT_TYPE: {
      return { ...state, currentPaymentType: action.payload };
    }
    case SET_SHOW_SAVED_PAYMENTS: {
      return { ...state, showSavedPayments: action.payload };
    }
    case SET_TITLE: {
      return { ...state, title: action.payload };
    }
    case SET_STORE_IN_VAULT: {
      return { ...state, storeInVault: action.payload };
    }
    case SET_FORM_ERRORS: {
      return { ...state, formErrors: action.payload };
    }
    case SET_FORM_ID: {
      return { ...state, formId: action.payload };
    }
    case SET_RECURRING: {
      return { ...state, recurring: action.payload };
    }
    case SET_FORM: {
      return { ...state, form: action.payload };
    }
    case SET_FORM_VALUES: {
      const { name, value } = action.payload;
      return { ...state, formValues: { ...state.formValues, [name]: value } };
    }
    case SEED_FORM_VALUES: {
      return { ...state, formValues: action.payload };
    }
    case SET_FORM_SUBMITTING: {
      return { ...state, formSubmitting: action.payload };
    }
    case SET_SAVED_PAYMENT_METHODS: {
      return {
        ...state,
        savedPaymentMethods: [...action.payload],
      };
    }
    case SET_PAYMENT_TYPES: {
      return {
        ...state,
        paymentTypes: action.payload,
      };
    }
    case RESET_PAYMENT_TYPES: {
      return {
        ...state,
        paymentTypes: ["card"],
      };
    }
    case SET_WEEKLY_DONATION: {
      return {
        ...state,
        isWeeklyDonation: action.payload,
      };
    }
    case SET_LOCAL_PAYMENT_CLIENT: {
      return {
        ...state,
        localPaymentClient: action.payload,
      };
    }
    case SET_SELECTED_AMOUNT_BUTTON: {
      return {
        ...state,
        selectedAmountButton: action.payload,
      };
    }
    default:
      return state;
  }
};

// Action creators
export function changeStep(payload) {
  return { type: CHANGED_STEP, payload };
}

export function setLocalPaymentClient(payload) {
  return { type: SET_LOCAL_PAYMENT_CLIENT, payload };
}

export function setPaymentMethods() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      paymentTypes: defaultPaymentTypes,
      currency,
      currentPaymentType,
      donationAmount,
    } = state.donation;
    const { country } = state.action_form;
    const localPaymentMethods = getLocalPaymentMethods({
      currency,
      country: country?.value,
      donationAmount,
    });

    let paymentTypes = new Set(defaultPaymentTypes);

    if (isPaypalSupported(currency)) {
      paymentTypes = new Set([...["paypal"], ...defaultPaymentTypes]);
    }

    if (isDirectDebitSupported(country?.value)) {
      paymentTypes.add("gocardless");
    }

    if (localPaymentMethods.length > 0) {
      paymentTypes = new Set([...paymentTypes, ...localPaymentMethods]);
    }

    dispatch(setPaymentTypes(Array.from(paymentTypes)));
    const [first] = paymentTypes;
    if (currentPaymentType !== first) {
      dispatch(setCurrentPaymentType(first));
    }
  };
}

export function currencyHasChanged(currency) {
  return (dispatch, getState) => {
    const state = getState();
    const { merchantAccounts, merchantAccountId: defaultMerchantAccountId } =
      state.donation || {};
    const merchantAccountId =
      merchantAccounts[currency.toUpperCase()] || defaultMerchantAccountId;

    const actions = [
      resetPaymentTypes(),
      setCurrency(currency),
      setMerchantAccountId(merchantAccountId),
      setPaymentMethods(),
    ];

    batchActions(dispatch, actions);
  };
}

export function setDonationQueryParams(query) {
  return (dispatch, getState) => {
    let actions = [];
    const { amount, currency, weekly } = query;
    const { donationBands } = getState().donation || {};
    const validCurrency = currency && donationBands[currency];

    if (amount) {
      const step = validCurrency ? 1 : 0;
      actions.push(setDonationAmount(amount), changeStep(step));
    }

    if (currency && validCurrency) {
      const { merchantAccounts, merchantAccountId: defaultMerchantAccountId } =
        getState().donation || {};
      const merchantAccountId =
        merchantAccounts[currency.toUpperCase()] || defaultMerchantAccountId;
      actions.push(setCurrency(currency));
      actions.push(setMerchantAccountId(merchantAccountId));
    }
    if (weekly === "true") actions.push(setWeeklyDonation(weekly));

    batchActions(dispatch, actions);
  };
}

export function setCurrency(payload) {
  return { type: SET_CURRENCY, payload };
}
export function setMerchantAccountId(payload) {
  return { type: SET_MERCHANT_ACCOUNT_ID, payload };
}
export function setDonationAmount(payload) {
  return { type: SET_DONATION_AMOUNT, payload };
}
export function setDonationBands(payload) {
  return { type: SET_DONATION_BANDS, payload };
}
export function resetPaymentTypes() {
  return { type: RESET_PAYMENT_TYPES, payload: {} };
}
export function selectDonationAmount(payload) {
  return (dispatch, getState) => {
    const state = getState();

    const baseTrackingData = selectBaseFacebookTrackingData(state);
    const memberId = getMemberId(state);

    logEventToFacebook(FB_EV_INITIATE_CHECKOUT, {
      ...baseTrackingData,
      value: payload,
      currency: getCurrency(state),
      ...(memberId && {
        user_id: memberId,
      }),
    });

    dispatch(setDonationAmount(payload));
  };
}
export function setIsCustomAmount(payload, amount) {
  return (dispatch, getState) => {
    dispatch({ type: SET_IS_CUSTOM_AMOUNT, payload });
  };
}
export function setCurrentPaymentType(payload) {
  return (dispatch, getState) => {
    dispatch({ type: SET_CURRENT_PAYMENT_TYPE, payload });
  };
}
export function setShowSavedPayments(payload) {
  return { type: SET_SHOW_SAVED_PAYMENTS, payload };
}
export function setTitle(payload) {
  return { type: SET_TITLE, payload };
}
export function setStoreInVault(payload = false) {
  return { type: SET_STORE_IN_VAULT, payload };
}
export function setFormErrors(payload) {
  return { type: SET_FORM_ERRORS, payload };
}
export function setDonationFormId(payload) {
  return { type: SET_FORM_ID, payload };
}
export function setRecurring(payload = false) {
  return { type: SET_RECURRING, payload };
}
export const setForm = (payload) => {
  return { type: SET_FORM, payload };
};
export function setFormSubmitting(payload) {
  return { type: SET_FORM_SUBMITTING, payload };
}
export function setSavedPaymentMethods(payload) {
  return { type: SET_SAVED_PAYMENT_METHODS, payload };
}
export function seedFormValues(payload) {
  return { type: SEED_FORM_VALUES, payload };
}
export function setPaymentTypes(payload) {
  return { type: SET_PAYMENT_TYPES, payload };
}
export function setWeeklyDonation(payload) {
  return { type: SET_WEEKLY_DONATION, payload };
}
export function setSelectedAmountButton(payload) {
  return (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_AMOUNT_BUTTON, payload });
  };
}
export function setFormValues(event) {
  const { target } = event;

  switch (target.type) {
    case "checkbox": {
      return {
        type: SET_FORM_VALUES,
        payload: {
          name: event.target.name,
          value: event.target.checked,
        },
      };
    }
    default: {
      return {
        type: SET_FORM_VALUES,
        payload: {
          name: event.target.name,
          value: event.target.value,
        },
      };
    }
  }
}

export const validateForm = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { donation, action_form, page } = state;
    const { country } = action_form;
    const { formValues, formId } = donation;
    const { id } = page;
    const payload = {
      ...formValues,
      form_id: formId,
    };
    if (country?.value) payload.country = country?.value;

    memberValidate(id, payload).then((resp) => {
      dispatch(setFormSubmitting(false));
      if (resp.errors) {
        dispatch(setFormErrors(resp.errors));
      } else {
        dispatch(changeStep(2));
      }
    });
  };
};

function savePaymentMethods(
  paymentMethodsInStore,
  paymentMethodsFromCookie,
  dispatch
) {
  if (paymentMethodsFromCookie?.length) {
    const actions = [
      setSavedPaymentMethods(
        [...paymentMethodsFromCookie],
        ...paymentMethodsInStore
      ),
      setShowSavedPayments(true),
    ];
    batchActions(dispatch, actions);
  }
}

export const fetchSavedPaymentMethods = () => {
  return (dispatch, getState) => {
    const state = getState();
    const isPaymentMethodCookiePresent = isCookiePresent(PAYMENT_METHODS_KEY);
    if (isPaymentMethodCookiePresent) {
      const { donation } = state;
      const { savedPaymentMethods } = donation;
      fetchPaymentMethod().then((resp) => {
        savePaymentMethods(savedPaymentMethods, JSON.parse(resp), dispatch);
        clearCookie(PAYMENT_METHODS_KEY);
      });
    }
  };
};

// Selectors
export const getCurrentStep = (state) => state.donation.currentStep;
export const getCurrency = (state) => state.donation.currency;
export const getMerchantAccountId = (state) => state.donation.merchantAccountId;
export const getDonationAmount = (state) => state.donation.donationAmount;
export const getIsCustomAmount = (state) => state.donation.isCustomAmount;
export const getDonationBands = (state) => state.donation.donationBands;
export const selectCapDonationBands = createSelector(
  getDonationBands,
  (donationBands) => {
    let cap = {};
    Object.entries(donationBands).forEach((donationBand) => {
      const currency = donationBand[0];
      const bands =
        donationBand[1].length > 5
          ? donationBand[1].slice(0, 5)
          : donationBand[1];
      cap[currency] = bands;
    });

    return cap;
  }
);
export const getCurrentPaymentType = (state) =>
  state.donation.currentPaymentType;
export const getShowSavedPayments = (state) => state.donation.showSavedPayments;
export const getShowDirectDebit = (state) => state.donation.showDirectDebit;
export const getPaymentTypes = (state) => state.donation.paymentTypes;
export const getTitle = (state) => state.donation.title;
export const getStoreInVault = (state) => state.donation.storeInVault;
export const getFormErrors = (state) => state.donation.formErrors;
export const getFormId = (state) => state.donation.formId;
export const getRecurring = (state) => state.donation.recurring;
export const getForm = (state) => state.donation.form;
export const getFormValues = (state) => state.donation.formValues;
export const getFormSubmitting = (state) => state.donation.formSubmitting;
export const getLocalPaymentClient = (state) =>
  state.donation.localPaymentClient;
export const getSavedPaymentMethods = (state) =>
  state.donation.savedPaymentMethods;
export const getWeeklyDonation = (state) => state.donation.isWeeklyDonation;
export const selectBaseFacebookTrackingData = createSelector(
  getPageId,
  getPageTitle,
  (page_id, content_name) => ({
    page_id,
    content_name,
    content_ids: [page_id],
    content_type: CONTENT_TYPE,
    product_catalog_id: PRODUCT_CATALOG_ID,
  })
);

// Action Types
export const CHANGED_STEP = "donation/change_step";
export const SET_CURRENCY = "donation/set_currency";
export const SET_MERCHANT_ACCOUNT_ID = "donation/set_merchant_account_id";
export const SET_DONATION_AMOUNT = "donation/set_donation_amount";
export const SET_IS_CUSTOM_AMOUNT = "donation/set_is_custom_amount";
export const SET_CURRENT_PAYMENT_TYPE = "donation/set_current_payment_type";
export const SET_SHOW_SAVED_PAYMENTS = "donation/set_show_saved_payments";
export const SET_TITLE = "donation/set_title";
export const SET_STORE_IN_VAULT = "donation/set_store_in_vault";
export const SET_FORM_ERRORS = "donation/set_form_error";
export const SET_FORM_ID = "donation/set_form_id";
export const SET_RECURRING = "donation/set_recurring";
export const SET_FORM = "donation/set_form";
export const SET_FORM_VALUES = "donation/set_form_values";
export const SEED_FORM_VALUES = "donation/seed_form_values";
export const SET_FORM_SUBMITTING = "donation/set_form_submitting";
export const SET_SAVED_PAYMENT_METHODS = "donation/set_saved_payment_methods";
export const SET_PAYMENT_TYPES = "donation/set_payment_types";
export const RESET_PAYMENT_TYPES = "donation/reset_payment_types";
export const SET_WEEKLY_DONATION = "donation/set_weekly_donation";
export const SET_DONATION_BANDS = "donation/set_donation_bands";
export const SET_LOCAL_PAYMENT_CLIENT = "donation/set_local_payment_client";
export const SET_LOCAL_PAYMENT_METHODS = "donation/set_local_payment_methods";
export const SET_SELECTED_AMOUNT_BUTTON = "donation/set_selected_amount_button";
