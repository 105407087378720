const SUPPORTED_DIRECT_DEBIT_COUNTRIES = [
  "GB",
  "NL",
  "FR",
  "DE",
  "AT",
  "ES",
  "AU",
  "BE",
  "CY",
  "EE",
  "IE",
  "IT",
  "LV",
  "LU",
  "MC",
  "PT",
  "SM",
  "SI",
];

export const isDirectDebitSupported = (country) =>
  SUPPORTED_DIRECT_DEBIT_COUNTRIES.includes(country);

export const isPaypalSupported = (currency) => {
  return currency !== "ARS";
};

export const getLocalPaymentMethods = ({
  currency,
  country,
  donationAmount,
}) => {
  const localPaymentMethods = [];

  // If currency is GBP, country has to be GB for SOFORT payment support
  if (
    currency === "GBP" &&
    country === "GB" &&
    donationAmount >= 1 &&
    donationAmount <= 5000
  ) {
    localPaymentMethods.push("sofort");
  }

  if (currency === "EUR") {
    // IDEAL
    if (country === "NL") localPaymentMethods.push("ideal");
    // GIROPAY
    if (country === "DE") localPaymentMethods.push("giropay");

    // SOFORT - Supports following countries
    if (
      ["AT", "BE", "DE", "NL", "ES"].includes(country) &&
      donationAmount >= 1 &&
      donationAmount <= 5000
    )
      localPaymentMethods.push("sofort");
  }

  return localPaymentMethods;
};
